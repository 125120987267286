import axios from 'axios'
import Store from '../store'

const service = axios.create({
  baseURL: process.env.VUE_APP_WalletApi,
  // withCredentials: true,
  // timeout: 5000
})

service.interceptors.request.use(
  config => {
    // const token = Store.state.token ? Store.state.token.access_token : null
    // if (token) {
    //     config.headers['Authorization'] = `Bearer ${token}`
    // }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const data = response.data
    return data
  },
  error => {
    return Promise.reject(error)
  }
)

export default service
