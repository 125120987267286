import { SDK } from 'sdk'
export default {
  SETSDK(state) {
    state.sdk = new SDK({
      appMsg: {
        name: 'ShowMoment',
        website: process.env.VUE_APP_Hosts,
        isProduction: process.env.NODE_ENV === 'production'
      },
      metaIdTag: process.env.VUE_APP_MetaIdTag,
      showmoneyApi: process.env.VUE_APP_WalletApi,
      getAccessToken: () => {
        return state.token?.access_token
      },
      callBackFail: () => {
        return new Promise((resolve) => {
          resolve()
        })
      },
      appOptions: {
        clientId: process.env.VUE_APP_AppId,
        clientSecret: process.env.VUE_APP_AppSecret
      },
      metaidjsOptions: {
        baseUri: process.env.VUE_APP_AuthUrl,
        oauthSettings: {
          clientId: process.env.VUE_APP_AppId,
          clientSecret: process.env.VUE_APP_AppSecret,
          redirectUri:
            process.env.VUE_APP_Hosts + process.env.VUE_APP_RedirectPath
        }
      },
      dotwalletOptions: {
        clientID: process.env.VUE_APP_DotWallet_AppId,
        clientSecret: process.env.VUE_APP_DotWallet_AppSecret,
        redirectUrl:
          process.env.VUE_APP_Hosts + process.env.VUE_APP_RedirectPath,
        env: process.env.VITE_DotWallet_ENV
      }
    })
  },
  SETTOKEN(state, payload) {
    localStorage.setItem('token', JSON.stringify(payload))
    state.token = payload
  },
  SETUSERINFO(state, payload) {
    state.userInfo = payload
    state.userInfoLoading = false

    localStorage.setItem('showId', payload.showId)
    localStorage.setItem('address', payload.address)
  },
  LOGOUT(state) {
    localStorage.removeItem('token')
    localStorage.removeItem('appType')
    state.token = null
    state.userInfo = null
    state.sdk = null
  },
  updateAppVersion(state, version) {
    state.appVersion = version
  },
  updateOtherUserInfo(state, otherUserInfo) {
    state.otherUserInfo = otherUserInfo
  }
}
