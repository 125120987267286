<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <van-tabbar v-model="active" route active-color="rgb(0, 0, 0)" inactive-color="rgb(204, 204, 204)" @change="onChange">
      <van-tabbar-item replace to="/index" icon="home-o">
        {{ $t('lang.home') }}
        <template #icon="props">
          <img :src="props.active ? require('./assets/img/icon_home_act.png') : require('./assets/img/icon_home.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/square" icon="home-o">
        {{ $t('lang.discover') }}
        <template #icon="props">
          <img :src="props.active ? require('./assets/img/icon_square_act.png') : require('./assets/img/icon_square.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace icon="home-o">
        {{ $t('lang.share') }}
        <template #icon="props">
          <img :src="props.active ? require('./assets/img/icon_publish_act.png') : require('./assets/img/icon_publish.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item v-if="$route.fullPath === '/photo'" replace to="/photo" icon="home-o">
        {{ $t('lang.gallery') }}
        <template #icon="props">
          <img :src="props.active ? require('./assets/img/icon_photo_act.png') : require('./assets/img/icon_photo.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item v-else replace to="/photo" icon="home-o">
        {{ $t('lang.gallery') }}
        <template #icon="props">
          <img :src="props.active ? require('./assets/img/icon_photo_act.png') : require('./assets/img/icon_photo.png')" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace :to="{name: 'User2', params: { txId }}" icon="friends-o">
        {{ $t('lang.me') }}
        <template #icon="props">
          <img :src="props.active ? require('./assets/img/icon_my_act.png') : require('./assets/img/icon_my.png')" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <van-popup v-model="showPublish" position="bottom" round class="publish_popup" @closed="selected=0">
      <div class="title">{{ $t('lang.share') }}</div>
      <div>
<!--        <div @click="goCommunity">-->
<!--          <img :src='require(`./assets/img/publish/icon_community${selected === 1 ? "_act" : ""}.png`)' alt="">-->
<!--          <div>{{ $t('lang.text') }}</div>-->
<!--        </div>-->
        <div @click="goPhoto">
          <img :src='require(`./assets/img/publish/icon_photo${selected === 2 ? "_act" : ""}.png`)' alt="">
          <div>{{ $t('lang.photos') }}</div>
        </div>
        <div @click="goVideo">
          <img :src='require(`./assets/img/publish/icon_video${selected === 3  ? "_act" : ""}.png`)' alt="">
          <div>{{ $t('lang.videos') }}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup } from 'vant'
import { getAppVersion } from '@/utils/utils'
export default {
  name: 'App',
  components: {
    [Popup.name]: Popup
  },
  data() {
    return {
      active: 0,
      showPublish: false,
      selected: 0
    }
  },
  computed: {
    txId() {
      return localStorage.getItem('showId')
    }
  },
  methods: {
    onChange(index) {
      this.active = 0
      if (index === 2) {
        this.showPublish = true
      }
    },
    goCommunity() {
      this.$toast(this.$t('lang.comingSoon'))
      return
      this.selected = 1

      if (!this.$store.state.userInfo) {
        this.$toast(this.$t('lang.loginTips'))
        return
      }
      this.showPublish = false
      this.$router.push({
        path: '/community_send'
      })
    },
    async goPhoto() {
      this.selected = 2

      if (!this.$store.state.userInfo) {
        this.$toast(this.$t('lang.loginTips'))
        return
      }
      this.showPublish = false
      this.$router.push({
        path: '/photo_send'
      })
    },
    async goVideo() {
      await getAppVersion()
      if (this.$store.state.isIOS &&
          this.$store.state.PhoneShow) {
        this.$toast(this.$t('lang.comingSoon'))
        return
      }

      this.selected = 3

      if (!this.$store.state.userInfo) {
        this.$toast(this.$t('lang.loginTips'))
        return
      }
      this.showPublish = false
      this.$router.push({
        path: '/video_send'
      })
    }
  }
}
</script>

<style lang="less" scoped>
/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}*/
.publish_popup {
  .title {
    margin: 30px 0 25px;
    color: #313131;
    font-size: 20px;
    text-align: center;
  }
  > div:last-child {
    margin: 0 87px 71px;
    display: flex;
    justify-content: space-between;
    > div {
      cursor: pointer;
      img {
        width: 55px;
        height: 55px;
      }
      div {
        text-align: center;
        font-size: 16px;
        color: #313131;
        margin-top: 6px;
      }
    }
  }
}
/deep/ .van-tabbar--fixed {
  max-width: 600Px;
  margin: 0 auto;
  right: 0;
}
/deep/ .van-popup--bottom {
  max-width: 600Px;
  margin: 0 auto;
  right: 0;
}
/deep/ .van-popover__action {
  width: initial;
}
</style>
