export default {
    async getUserInfo ({ state, commit, dispatch }) {
        state.userInfoLoading = true
        const res = await state.sdk?.getUserInfo()
        if (res && res.code === 200) {
            commit('SETUSERINFO', res.data)
            if (state.sdk.isApp && res.appAccessToken) {
                commit('SETTOKEN', {
                    access_token: res.appAccessToken,
                })
            }
        } else {
            state.sdkInitIng = false
            state.userInfoLoading = false
            dispatch('LogOut')
        }
    },
    refreshToken ({ state, commit, dispatch }) {
        return new Promise(async (resolve, reject) => {
            if (state.token) {
                const res = await state.sdk
                    ?.refreshToken({ refreshToken: state.token?.refresh_token })
            .catch(() => {
                    dispatch('LogOut')
                })
                if (res) {
                    commit('SETTOKEN', res)
                    resolve()
                } else {
                    dispatch('LogOut')
                    new Error('refresh_token fail')
                    reject('refresh_token fail')
                }
            } else {
                dispatch('LogOut')
                new Error('refresh_token fail')
                reject('refresh_token fail')
            }
        })
    },
    initSdk ({ state, commit, dispatch }) {
        return new Promise(resolve => {
            state.sdkInitIng = true
            state.userInfoLoading = true
            state.sdk
                ?.initSdk()
                .then(() => {
                    state.sdkInitIng = false
                    dispatch('getUserInfo')
                    resolve()
                })
                .catch(() => {
                    state.sdkInitIng = false
                    dispatch('LogOut')
                    resolve()
                })
        })
    },
    LogOut({ state, commit, dispatch }) {
        return new Promise(resolve => {
            commit('LOGOUT')
            commit('SETSDK')
            resolve()
        })
    }
}
