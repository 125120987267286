import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'lib-flexible/flexible'
import store from './store'
import i18n from "./lang";
import { Lazyload } from 'vant'
Vue.use(Lazyload)
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.config.productionTip = false

// import Layout from '@/components/layout/Layout'
// Vue.component('layout', Layout)
Vue.component('layout', () => import('@/components/layout/Layout'))

import { Button, Tabbar, TabbarItem, Dialog, Toast, Notify, Swipe, SwipeItem, List, Image as VanImage, Checkbox, CheckboxGroup, ImagePreview } from 'vant'

Vue.use(Button)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Dialog)
Vue.use(Toast)
Vue.use(Notify)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(List)
Vue.use(VanImage)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(ImagePreview)

Vue.prototype.$appMetaIdJs = window.appMetaIdJsV2
    ? window.appMetaIdJsV2
    : window.appMetaIdJs
        ? window.appMetaIdJs
        : null

Date.prototype.format = function(format) {
  var o = {
    "M+" : this.getMonth()+1, //month
    "d+" : this.getDate(),    //day
    "h+" : this.getHours(),   //hour
    "m+" : this.getMinutes(), //minute
    "s+" : this.getSeconds(), //second
    "q+" : Math.floor((this.getMonth()+3)/3),  //quarter
    "S" : this.getMilliseconds() //millisecond
  }
  if(/(y+)/.test(format)) format=format.replace(RegExp.$1,
      (this.getFullYear()+"").substr(4 - RegExp.$1.length));
  for(var k in o)if(new RegExp("("+ k +")").test(format))
    format = format.replace(RegExp.$1,
        RegExp.$1.length==1 ? o[k] :
            ("00"+ o[k]).substr((""+ o[k]).length));
  return format;
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
