import { getSensibleTxData } from 'api/api'
import Store from '../store'
export const getTxDataCycle = (
    txId,
    currentTimer = 0,
    maxTimer = 10,
    parentResolve
) => {
    return new Promise(async (resolve, reject) => {
        debugger
        const _currentTimer = currentTimer + 1
        const res = await getSensibleTxData(txId).catch(error => {
            getTxDataCycle(
                txId,
                _currentTimer,
                maxTimer,
                parentResolve ? parentResolve : resolve
            )
        })
        if (
            res &&
            res.code === 0 &&
            res.data.txid
        ) {
            parentResolve ? parentResolve(res) : resolve(res)
        } else {
            if (_currentTimer >= maxTimer) {
                reject()
            } else {
                setTimeout(() => {
                    getTxDataCycle(
                        txId,
                        _currentTimer,
                        maxTimer,
                        parentResolve ? parentResolve : resolve
                    )
                }, 1000)
            }
        }
    })
}
export const getAppVersion = () => {
    return new Promise(resolve => {
        if (
            Store.state.isIOS &&
            Store.state.PhoneShow &&
            window.appMetaIdJsV2.getiOSVersion
        ) {
            const functionName = 'getiOSVersionCallBack'
            window[functionName] = res => {
                res = JSON.parse(res)
                Store.commit(
                    'updateAppVersion',
                    parseFloat(res.data)
                )
                resolve()
            }
            window.appMetaIdJsV2.getiOSVersion('', '', functionName)
        } else {
            resolve()
        }
    })
}