export const lang = {
    title: 'Welcome to ShowMoment',
    noLoginTips: 'Explore a new life on the chain',
    noFollowed: 'Follow users to view their shared photos and videos',
    viewSquare: 'view Square',
    index: 'index',
    follow: 'Follow',
    like: 'Like',
    comment: 'Comment',
    tip: 'Tip',
    home: 'Home',
    discover: 'Discover',
    share: 'Share',
    photos: 'Photos',
    gallery: 'Gallery',
    videos: 'Videos',
    upload: 'Upload',
    login: 'Login',
    me: 'Me',
    edit: 'Edit',
    likes: 'Likes',
    followers: 'Followers',
    following: 'Following',
    favorites: 'Favorites',
    private: 'Private',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    cancel: 'Cancel',
    newPost: 'New Post',
    text: 'Text',
    shareTips: 'What’s on your mind?',
    uploadTips: 'Upload photos/videos,<br>share your precious moment.',
    favTips: 'Add photos/videos you liked to Favorites.<br>Only visible to you.',
    privateTips: 'Photos/videos are only visible to you.',
    addToBlacklist: 'Add to blacklist',
    copyLink: 'Copy Link',
    checkTX: 'Check TX',
    loginTips: 'Please Sign In',
    otherAmount: 'Other amount',
    enterTheAmount: 'Enter the amount',
    fixedAmount: 'Fixed amount',
    mintNFT: 'Mint NFT',
    addTo: 'Add to',
    more: 'More',
    details: 'Details',
    format: 'Format:',
    uploadTime: 'Upload Time:',
    items: ' items',
    comingSoon: 'Coming soon',
    blacklistTips: 'By adding the user to the blacklist, you will not able to view their content.',
    allComments: 'All Comments',
    leaveYourComment: 'Leave your comment',
    noCommentYet: 'No comment yet',
    totalComment: 'Total {0} comment',
    reply: 'Reply',
    fromLocal: 'Upload from local',
    onChain: 'Select from the on-chain gallery',
    linkCopied: 'Link copied',
    ok: 'Ok',
    emptyContent: 'Content cannot be empty',
    media: 'Media',
    uploadOrImport: 'Upload/Import',
    uploadOrImportTips: 'Upload Or Import',
    success: 'success',
    personalCenter: 'Personal Center',
    appCenter: 'App Center',
    about: 'About',
    logout: 'Log out',
    protocol: 'protocol',
    onTheChain: 'on The Chain...',
    connectWallet: 'Connect Wallet'
}