import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store'
import {Toast} from 'vant'

// import Home from '@/views/home/Home'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: Home,
  //   children: [
  //     {
  //       path: 'photo',
  //       name: 'Photo',
  //       component: () => import('@/views/album/PhotoList')
  //     },
  //     {
  //       path: 'album',
  //       name: 'Album',
  //       component: () => import('@/views/album/AlbumList')
  //     }
  //   ]
  // },
  {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/index/Index')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login'),
  }, {
    path: '/photo',
    name: 'Photo',
    component: () => import('@/views/album/PhotoList'),
    meta: {
      keepAlive: true
    },
    children: [
        {
          path: 'view',
          name: 'PhotoView',
          component: () => import('@/views/album/children/PhotoView')
        }
    ]
  }, {
    path: '/album',
    name: 'Album',
    component: () => import('@/views/album/AlbumList'),
    children: [
      {
        path: 'detail',
        name: 'AlbumDetail',
        components: {
          default: () => import('@/views/album/children/AlbumDetail'),
          // detail: () => import('@/views/album/children/AlbumDetail')
        },
        // props:(route) => {
        //   txId: route.query.txId
        // },
        props: {
          default: (route) => {
            txId: route.query.txId
          }
        }
      }
    ]
  }, {
    path: '/albumChooseup',
    name: 'AlbumChooseup',
    component: () => import('@/views/album/children/AlbumChooseup')
  }, {
    path: '/square',
    name: 'Square',
    component: () => import('@/views/square/Square'),
    children: [
      {
        path: 'detail',
        name: 'SquareDetail',
        component: () => import('@/views/square/children/SquareDetail')
      }
    ]
  }, {
    path: '/community_send',
    name: 'CommunitySend',
    component: () => import('@/views/publish/Community')
  },
  {
    path: '/photo_send',
    name: 'PhotoSend',
    component: () => import('@/views/publish/Photo')
  },
  {
    path: '/photo_send2',
    name: 'PhotoSend2',
    component: () => import('@/views/publish/Photo2'),
    children: [
      {
        path: '/chooseup',
        name: 'Chooseup',
        component: () => import('@/views/album/children/Chooseup')
      }
    ]
  }, {
    path: '/video_send',
    name: 'VideoSend',
    component: () => import('@/views/publish/Video')
  }, {
    path: '/user',
    name: 'User',
    component: () => import('@/views/user/User'),
  }, {
    path: '/user2/:txId',
    name: 'User2',
    component: () => import('@/views/user/User2'),
  }, {
    path: '/findtime',
    name: 'FindTime',
    component: () => import('@/views/findtime/FindTime'),
    children: [
        {
          path: 'publish',
          name: 'Publish',
          component: () => import('@/views/findtime/Publish')
        }
    ]
  }, {
    path: '/about',
    name: 'About',
    component: () => import('@/views/about/About')
  }, {
    path: '/',
    redirect: '/index'
  }
]
console.log(process.env.BASE_URL)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async(to, from, next) => {
  Toast.loading({
    message: 'Loading',
    forbidClick: true,
    duration: 0
  })

  // 推荐码
  const refCode = to.query.refCode || ''
  if (refCode) {
    localStorage.setItem('refCode', refCode)
  }

  if (!Store.state.sdk) {
    Store.commit('SETSDK')
  }
  const isApp = Store.state.sdk.isApp
  if (isApp) {
    // 设置app环境
    if (Store.state.sdk.type !== 1) Store.state.sdk.changeSdkType(1)
    //  没有用户信息， 也没有正在加载用户信息, 则去获取用户信息
    if (!Store.state.userInfo && !Store.state.userInfoLoading) {
      Store.dispatch('getUserInfo')
    }
  } else {
    const token = Store.state.token
    if (token) {
      // 检查环境变量
      if (Store.state.sdk?.type === -1) {
        const appType = window.localStorage.getItem('appType')
        if (appType && appType !== '') Store.state.sdk?.changeSdkType(parseInt(appType))
      }

      const now = new Date().getTime()
      if (token.expires_time && now >= token.expires_time) {
        await Store.dispatch('refreshToken')
      }

      if (!Store.state.sdk?.isSdkFinish && !Store.state.sdkInitIng) {
        Store.dispatch('initSdk')
      }
    } else {
      // next('/')
    }
  }
  next()
})
router.afterEach((to, from) => {
  Toast.clear()
})

export default router
