var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "keep-alive",
        [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
        1
      ),
      !_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e(),
      _c(
        "van-tabbar",
        {
          attrs: {
            route: "",
            "active-color": "rgb(0, 0, 0)",
            "inactive-color": "rgb(204, 204, 204)",
          },
          on: { change: _vm.onChange },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c(
            "van-tabbar-item",
            {
              attrs: { replace: "", to: "/index", icon: "home-o" },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function (props) {
                    return [
                      _c("img", {
                        attrs: {
                          src: props.active
                            ? require("./assets/img/icon_home_act.png")
                            : require("./assets/img/icon_home.png"),
                        },
                      }),
                    ]
                  },
                },
              ]),
            },
            [_vm._v(" " + _vm._s(_vm.$t("lang.home")) + " ")]
          ),
          _c(
            "van-tabbar-item",
            {
              attrs: { replace: "", to: "/square", icon: "home-o" },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function (props) {
                    return [
                      _c("img", {
                        attrs: {
                          src: props.active
                            ? require("./assets/img/icon_square_act.png")
                            : require("./assets/img/icon_square.png"),
                        },
                      }),
                    ]
                  },
                },
              ]),
            },
            [_vm._v(" " + _vm._s(_vm.$t("lang.discover")) + " ")]
          ),
          _c(
            "van-tabbar-item",
            {
              attrs: { replace: "", icon: "home-o" },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function (props) {
                    return [
                      _c("img", {
                        attrs: {
                          src: props.active
                            ? require("./assets/img/icon_publish_act.png")
                            : require("./assets/img/icon_publish.png"),
                        },
                      }),
                    ]
                  },
                },
              ]),
            },
            [_vm._v(" " + _vm._s(_vm.$t("lang.share")) + " ")]
          ),
          _vm.$route.fullPath === "/photo"
            ? _c(
                "van-tabbar-item",
                {
                  attrs: { replace: "", to: "/photo", icon: "home-o" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function (props) {
                          return [
                            _c("img", {
                              attrs: {
                                src: props.active
                                  ? require("./assets/img/icon_photo_act.png")
                                  : require("./assets/img/icon_photo.png"),
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1881473548
                  ),
                },
                [_vm._v(" " + _vm._s(_vm.$t("lang.gallery")) + " ")]
              )
            : _c(
                "van-tabbar-item",
                {
                  attrs: { replace: "", to: "/photo", icon: "home-o" },
                  scopedSlots: _vm._u([
                    {
                      key: "icon",
                      fn: function (props) {
                        return [
                          _c("img", {
                            attrs: {
                              src: props.active
                                ? require("./assets/img/icon_photo_act.png")
                                : require("./assets/img/icon_photo.png"),
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" " + _vm._s(_vm.$t("lang.gallery")) + " ")]
              ),
          _c(
            "van-tabbar-item",
            {
              attrs: {
                replace: "",
                to: { name: "User2", params: { txId: _vm.txId } },
                icon: "friends-o",
              },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function (props) {
                    return [
                      _c("img", {
                        attrs: {
                          src: props.active
                            ? require("./assets/img/icon_my_act.png")
                            : require("./assets/img/icon_my.png"),
                        },
                      }),
                    ]
                  },
                },
              ]),
            },
            [_vm._v(" " + _vm._s(_vm.$t("lang.me")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          staticClass: "publish_popup",
          attrs: { position: "bottom", round: "" },
          on: {
            closed: function ($event) {
              _vm.selected = 0
            },
          },
          model: {
            value: _vm.showPublish,
            callback: function ($$v) {
              _vm.showPublish = $$v
            },
            expression: "showPublish",
          },
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("lang.share"))),
          ]),
          _c("div", [
            _c("div", { on: { click: _vm.goPhoto } }, [
              _c("img", {
                attrs: {
                  src: require("./assets/img/publish/icon_photo" +
                    (_vm.selected === 2 ? "_act" : "") +
                    ".png"),
                  alt: "",
                },
              }),
              _c("div", [_vm._v(_vm._s(_vm.$t("lang.photos")))]),
            ]),
            _c("div", { on: { click: _vm.goVideo } }, [
              _c("img", {
                attrs: {
                  src: require("./assets/img/publish/icon_video" +
                    (_vm.selected === 3 ? "_act" : "") +
                    ".png"),
                  alt: "",
                },
              }),
              _c("div", [_vm._v(_vm._s(_vm.$t("lang.videos")))]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }