export const lang = {
    title: '欢迎加入ShowMoment',
    noLoginTips: '探索全新链上生活',
    noFollowed: '关注用户即可查看他们分享的照片和视频。',
    viewSquare: '去广场逛一下',
    index: '首页',
    follow: '关注',
    like: '点赞',
    comment: '评论',
    tip: '打赏',
    home: '首页',
    discover: '广场',
    share: '发布',
    photos: '照片',
    gallery: '相册',
    videos: '视频',
    upload: '上传照片',
    login: '请先登录',
    me: '我的',
    edit: '编辑主页',
    likes: '获赞',
    followers: '粉丝',
    following: '关注中',
    favorites: '收藏',
    private: '私密',
    signIn: '登录',
    signUp: '注册',
    cancel: '取消',
    newPost: '发新贴',
    text: '社区',
    shareTips: '分享新鲜事...',
    uploadTips: '上传你珍贵的照片和视频<br>分享美好时光',
    favTips: '收藏感兴趣的照片和视频，方便再次查看，<br>收藏内容只对你可见，且不会通知任何人。',
    privateTips: '私密内容只对你可见，且不会通知任何人。',
    addToBlacklist: '加入黑名单',
    copyLink: '复制链接',
    checkTX: '查看TX',
    loginTips: '请登录账户',
    otherAmount: '其他金额',
    enterTheAmount: '输入金额',
    fixedAmount: '固定金额',
    mintNFT: '一键铸造NFT',
    addTo: '添加到',
    more: '更多',
    details: '照片详情',
    format: '照片格式：',
    uploadTime: '上传时间：',
    items: ' 张图片',
    comingSoon: '此功能暂未开放',
    blacklistTips: '拉黑后，您将不会看到黑名单用户发布的内容',
    allComments: '全部评论',
    leaveYourComment: '留下你精彩的评论',
    noCommentYet: '暂无评论',
    totalComment: '全部 {0} 条评论',
    reply: '回复',
    fromLocal: '上传图片',
    onChain: '从链上相册中选择',
    linkCopied: '链接已复制到剪贴板',
    ok: '确定',
    emptyContent: '内容不能为空',
    media: '媒体',
    uploadOrImport: '上传/导入',
    uploadOrImportTips: '上传或者导入图片',
    success: '成功',
    personalCenter: '个人中心',
    appCenter: '应用广场',
    about: '关于应用',
    logout: '退出登录',
    protocol: '协议',
    onTheChain: '上链中...',
    connectWallet: '连接钱包'
}