const tokenString = localStorage.getItem('token')
const token = tokenString ? JSON.parse(tokenString) : null
const UA = window.navigator.userAgent.toLowerCase()
const isAndroid = UA && UA.indexOf('android') > 0
const isIOS = UA && /iphone|ipad|ipod|ios/.test(UA)
export default {
  token,
  sdk: null,
  userInfo: null,
  userInfoLoading: false,
  sdkInitIng: false,
  isIOS,
  isAndroid,
  PhoneShow: window.appMetaIdJsV2 || window.appMetaIdJs ? true : false,
  appVersion: isIOS && window.iOSVersion ? parseInt(window.iOSVersion) : 0,
  otherUserInfo: {}
}
