import Vue from 'vue'
import VueI18n from 'vue-i18n'
const lang = localStorage.getItem('lang') || 'zh'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: lang, // set locale
    messages: {
        zh: require('@/assets/lang/zh.js'), // 中文语言包
        en: require('@/assets/lang/en.js') // 英文语言包
    }
})

export default i18n
