import request from '@/utils/request'

export const getMyMetaFileList = (params) => request.post('/aggregation/v2/app/metaFile/getMyMetaFileList', params)

export const getImageMetaFile = (params) => request.post('/aggregation/v2/app/metaFile/getImageMetaFile', params)

export const getBuzzCustomizeList = (params) => request.post('/aggregation/v2/app/buzz/getBuzzCustomizeList', params)

export const getBuzzInteractiveList = (params) => request.post('/aggregation/v2/app/buzz/getBuzzInteractiveList', params)

export const queryFindMetaData = (query) => request.post('/v2showMANDB/api/v1/query/queryFindMetaData/' + btoa(JSON.stringify(query)))

export const getMetaPhotoBuzzList = (query) => request.get(`/aggregation/v2/app/metaPhoto/getMetaPhotoBuzzList/${query.metaId}`, {params: query})

export const getPiazzaList = (query) => request.get(`/aggregation/v2/app/metaPhoto/getPiazzaList/${query.metaId}`, {params: query})

export const getMyMetaPhotoList = (query) => request.get(`/aggregation/v2/app/metaPhoto/getMyMetaPhotoList/${query.metaId}/${query.encrypt}`, {params: query})

export const getMyMetaPhotoFolder = (query) => request.get(`/aggregation/v2/app/metaPhoto/getMyMetaPhotoFolder/${query.metaId}${!query.folderMetanetId ? '' : '/' + query.folderMetanetId}`, {params: query})

export const getMyMetaPhotoItemTxList = (query) => request.get(`/aggregation/v2/app/metaPhoto/getMyMetaPhotoItemTxList/${query.metaId}${!query.folderMetanetId ? '' : '/' + query.folderMetanetId}`, {params: query})

export const getMyFollow = (params) => request.post(`/aggregation/v2/app/follow/getMyFollow`, params)

export const getOneBuzz = (params) => request.post(`/aggregation/v2/app/buzz/getOneBuzz`, params)

export const getMyPiazzaList = (query) => request.get(`/aggregation/v2/app/metaPhoto/getMyPiazzaList/${query.metaId}`, {params: query})

export const getUserInfo = (params) => request.post('/aggregation/v2/app/user/getUserInfo', params)

export const getSensibleTxData = (txId) => request.get(`https://api.sensiblequery.com/tx/${txId}`)
